td:first-child {
  width: 15em;
  padding-top: 15px !important;
}
.recipe-cell {
  width: 5em;
  text-align: right;
  padding-top: 15px !important;
}
.ingredient-cell {
  width: 10em;
}
svg .sugar {
  fill: #f5f5f5;
}
svg .guarana {
  fill: #811;
}
svg .citric-acid {
  fill: #bf0;
}
svg .sodiumbicarbonate {
  fill: #1cc;
}
svg .aroma {
  fill: #e0e;
}
svg .caffeine {
  fill: #fa0;
}
.footer {
  text-align: center;
}
